<div class="link" [class.remote]="!local" [title]="plugin.config?.description || ''">
  <a [routerLink]="['/tag', qualifiedTag]">{{ plugin.name || plugin.tag }}</a>
  <span *ngIf="plugin.name" class="host">({{ qualifiedTag }})</span>
  <span *ngIf="!plugin.name && plugin.origin" class="host">({{ plugin.origin }})</span>
</div>
<div class="stack">
  <div class="info">
    <span [title]="plugin.modified?.toISO()" i18n>modified {{ plugin.modified?.toRelative() }}</span>
    <ng-container *ngIf="!local" i18n>
      on <a [routerLink]="['/tag', plugin.origin]">{{ plugin.origin }}</a>
    </ng-container>
  </div>
  <div class="actions">
    <a *ngIf="!local"
       class="fake-link"
       (click)="copy()" i18n>copy</a>
    <a *ngIf="local && store.account.admin else source"
       class="fake-link"
       (click)="editing = !editing" i18n>edit</a>
    <ng-template #source>
      <a class="fake-link"
         (click)="viewSource = !viewSource" i18n>source</a>
    </ng-template>
    <a class="fake-link"
       (click)="download()" i18n>download</a>
    @if (plugin.config?.export) {
      <a class="fake-link"
         (click)="export()" i18n>export</a>
    }
    <a *ngIf="!deleting && store.account.admin"
       class="fake-link"
       (click)="deleting = true" i18n>delete</a>
    <span *ngIf="deleting" class="delete-confirm" i18n>are you sure?
      <a class="fake-link" (click)="delete()">yes</a> /
      <a class="fake-link" (click)="deleting = false">no</a>
    </span>
  </div>
</div>

<form *ngIf="editing else showErrors" class="form" [formGroup]="editForm" (ngSubmit)="save()">

  <app-plugin-form [group]="editForm"
                   [configErrors]="configErrors"
                   [defaultsErrors]="defaultsErrors"
                   [schemaErrors]="schemaErrors"></app-plugin-form>

  @for (e of serverError; track e) {
    <span><!-- Unexpected Error --></span>
    <div class="error">{{ e }}</div>
  }

  <span><!-- Buttons --></span>
  <span class="buttons right">
    <button type="submit" [disabled]="submitted && !editForm.valid" i18n>save</button>
    <button type="button" (click)="editing = false" i18n>cancel</button>
  </span>
</form>

<form *ngIf="viewSource" class="form" [formGroup]="editForm">
  <app-plugin-form [group]="editForm"></app-plugin-form>
</form>

<ng-template #showErrors>
  @for (e of serverError; track e) {
    <div class="error">{{ e }}</div>
  }
</ng-template>
