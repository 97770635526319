import { DateTime } from 'luxon';
import { Plugin } from '../model/plugin';

export const cronPlugin: Plugin = {
  tag: '+plugin/cron',
  name: $localize`⏱️ Scheduler`,
  config: {
    default: true,
    generated: $localize`Generated by jasper-ui ${DateTime.now().toISO()}`,
    icons: [{ label: $localize`⏱️`, order: -3 }],
    filters: [{ query: '+plugin/cron', label: $localize`⏱️ Scheduled`, title: $localize`Scheduled script`, group: $localize`Plugins 🧰️` }],
    description: $localize`Schedule scripts to be run on the server at specific intervals.`,
    // language=Handlebars
    infoUi: `every {{formatInterval interval}}`,
    form: [{
      key: 'interval',
      type: 'duration',
      defaultValue: 'PT15M',
    }],
  },
  defaults: {
    interval: 'PT15M',
  },
  schema: {
    properties: {
      interval: { type: 'string' },
    },
  },
};
