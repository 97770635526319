<div class="link" [class.remote]="!local">
  <a [routerLink]="['/tag', extLink]" [appTitle]="ext">{{ ext.name || template?.name || plugin?.name || ext.tag }}</a>
  <span *ngIf="ext.name" class="host">({{ qualifiedTag }})</span>
  <span *ngIf="!ext.name && ext.origin" class="host">({{ ext.origin }})</span>
</div>
<div class="stack">
  <div class="info">
    <ng-container *ngIf="ext.modified else notSubmitted">
      <span [title]="ext.modified.toISO()" i18n>modified {{ ext.modified.toRelative() }}</span>
    </ng-container>
    <ng-template #notSubmitted>
      <span i18n>not found</span>
    </ng-template>
    <ng-container *ngFor="let icon of icons">
      <span class="icon" (click)="bookmarks.toggleTag(icon.tag)" [title]="icon.tag">{{ icon.config!.view! }}</span>
    </ng-container>
    <ng-container *ngIf="!local" i18n>
      on <a [routerLink]="['/tag', ext.origin]">{{ ext.origin || 'default' }}</a>
    </ng-container>
  </div>
  <div class="actions">
    <ng-container *ngIf="!ext.upload">
      <a *ngIf="parent" [routerLink]="['/tags', parent]" i18n>parent</a>
      <a [routerLink]="['/tags', extLink]" i18n>tags</a>
      <a *ngIf="!local" class="fake-link" (click)="copy()" i18n>copy</a>
      <a *ngIf="local && writeAccess else source" class="fake-link" (click)="editing = !editing" i18n>edit</a>
      <ng-template #source>
        <a *ngIf="!local" class="fake-link" (click)="editing = !editing" i18n>diff</a>
        <a *ngIf="local" class="fake-link" (click)="viewSource = !viewSource" i18n>source</a>
      </ng-template>
      <a class="fake-link" (click)="download()" i18n>download</a>
      <app-confirm-action #action *ngIf="store.account.mod || writeAccess" [action]="delete$" i18n>delete</app-confirm-action>
    </ng-container>
    <ng-container *ngIf="store.account.user && ext.upload">
      <a class="fake-link" (click)="upload()" i18n>upload</a>
      <a *ngIf="parent" [routerLink]="['/tags', parent]" i18n>parent</a>
      <a [routerLink]="['/tags', ext.tag]" i18n>tags</a>
      <a class="fake-link" (click)="editing = !editing" i18n>edit</a>
      <a class="fake-link" (click)="download()" i18n>download</a>
      <app-confirm-action #action [action]="delete$" i18n>delete</app-confirm-action>
    </ng-container>
  </div>
</div>

<form *ngIf="editing else showErrors" class="form" [formGroup]="editForm" (ngSubmit)="save()">

  <app-ext-form [group]="editForm"></app-ext-form>

  @for (e of serverError; track e) {
    <span><!-- Unexpected Error --></span>
    <div class="error">{{ e }}</div>
  }

  <span><!-- Buttons --></span>
  <span class="buttons right">
    <div *ngIf="invalid && !force" class="overwrite warning" i18n-title title="Will drop all unknown configs.">
      <input id="overwrite" type="checkbox" [(ngModel)]="overwrite" [ngModelOptions]="{standalone: true}">
      <label for="overwrite" i18n>Overwrite</label>
    </div>
    <div *ngIf="invalid && force" class="force error" i18n-title title="Will reset config to defaults.">
      <input id="force" type="checkbox" [(ngModel)]="force" [ngModelOptions]="{standalone: true}">
      <label for="force" i18n>Force</label>
    </div>
    <button type="submit" [disabled]="submitted && !editForm.valid" i18n>save</button>
    <button type="button" (click)="editing = false" i18n>cancel</button>
  </span>
</form>

<form *ngIf="viewSource" class="form" [formGroup]="editForm">
  <app-ext-form [group]="editForm"></app-ext-form>
</form>

<ng-template #showErrors>
  @for (e of serverError; track e) {
    <div class="error">{{ e }}</div>
  }
</ng-template>
