import { DateTime } from 'luxon';
import { Plugin } from '../model/plugin';

export const threadPlugin: Plugin = {
  tag: 'plugin/thread',
  name: $localize`🧵️ Threads`,
  config: {
    type: 'plugin',
    default: true,
    genId: true,
    internal: true,
    reply: ['internal', 'plugin/thread'],
    responseButton: $localize`🧵️`,
    generated: $localize`Generated by jasper-ui ${DateTime.now().toISO()}`,
    description: $localize`Attempt to merge similar Refs tagged plugin/thread into threads.`,
    icons: [{ thumbnail: $localize`🧵️`, order: -1 }],
    filters: [
      { query: 'plugin/thread', label: $localize`🧵️ threads`, title: $localize`Discussion Threads or DMs`, group: $localize`Plugins 🧰️` },
    ],
  },
  generateMetadata: true,
};
