@if (isTemplate('graph')) {
  <app-force-directed [tag]="tag"
                      [content]="page?.content || []"></app-force-directed>
} @else if (isTemplate('kanban')) {
  <app-kanban [query]="tag"
              [ext]="ext!"
              [fullPage]="fullPage"
              [pageControls]="pageControls"
              [size]="size"
              [sort]="sort"
              [filter]="filter"
              [search]="search"></app-kanban>
} @else if (isTemplate('blog')) {
  <app-blog [ext]="ext!"
            [page]="page"
            [pageControls]="pageControls"
            [cols]="cols"></app-blog>
} @else if (isTemplate('chat')) {
  <app-chat [query]="tag"></app-chat>
} @else if (isTemplate('folder')) {
  <app-folder [page]="page"
              [tag]="tag"
              [ext]="ext!"></app-folder>
} @else {
  <app-ref-list [class]="cssClass(ext?.tag)"
                [ext]="ext"
                [page]="page"
                [pageControls]="pageControls"
                [plugins]="plugins"
                [cols]="cols"
                [showAlarm]="showAlarm"
                [showVotes]="showVotes"></app-ref-list>
}
