<ng-container *ngIf="page else loading">
  <div class="blog-container"
       [class.grid-layout]="colStyle"
       [style.grid-template-columns]="colStyle">
    <app-blog-entry *ngFor="let ref of pinned trackBy: trackByUrlOrigin"
                    class="pinned"
                    [ref]="ref"></app-blog-entry>
    <app-blog-entry *ngFor="let ref of page?.content trackBy: trackByUrlOrigin"
                    [ref]="ref"
                    [blog]="ext"></app-blog-entry>
  </div>
  <p class="no-results" *ngIf="!page.content.length">
    {{ emptyMessage }}
  </p>
  <app-page-controls *ngIf="pageControls && page.content.length"
                     [defaultCols]="cols"
                     [page]="page"></app-page-controls>
</ng-container>
<ng-template #loading>
  <app-loading></app-loading>
</ng-template>
